import React, { useState, useEffect } from "react";
import { Button, Card, ListGroup, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router";
import "../../css/master.css";

import ViewBillDetails from "./ViewBillDetails";

export default function PayBill() {
  const [variable, setvar] = useState(true);

  return (
    <>
      <div class="container text-center">
        <div className="row">
          <div className="col-md-3">
            <h6 class="centerHead">
              <u>Pending Bills</u>
            </h6>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Select Project</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select Project</option>
                  <option value="1">Project 1</option>
                  <option value="2">Project 2</option>
                  <option value="3">Project 3</option>
                </Form.Select>
              </Form.Group>
            </Form>
            <hr></hr>
            <Button onClick={() => setvar(true)}>Pay Total Amount</Button>
            &nbsp;
            <Button onClick={() => setvar(false)}>Pay A Bill</Button>
            <hr></hr>
            <ListGroup as="ul">
              <ListGroup.Item as="li" active>
                Bill Date - Bill Number
              </ListGroup.Item>
              <ListGroup.Item as="li">12.05.2022 - #23456</ListGroup.Item>
              <ListGroup.Item as="li">Bill Date - Bill Number</ListGroup.Item>
            </ListGroup>
            <hr></hr>
            <b>Total Amount Pending: </b>₹14000.00
          </div>
          <div class="col-md-1">
            <div class="vll"></div>
          </div>
          <div className="col-md-8">
            {variable ? (
              <div className="text-center">
                <p>
                  <b>Total Amount Pending: </b>₹14000.00
                </p>

                <p>
                  Total amount is the total of all pending bills. For example,
                  if your billing preference is set to 3 months, you will
                  receive 3 bills for each month and the total amount shall be
                  the total of all the three months . To view, download, or
                  raise dispute for specific bills, please go to 'Bills &amp;
                  Invoices' tab.
                </p>
              </div>
            ) : (
              <div className="left">
                <p>
                  <b>Invoice Number: </b>InvNum
                </p>
                <p>
                  <b>Invoice Date: </b>Date
                </p>
                <p>
                  <b>Billed To: </b>Org name
                </p>
                <p>
                  <b>Project: </b>Project Name
                </p>
                <p>
                  <b>Invoice Details: </b>Lorem Ipsum is simply dummy text of
                  the printing and typesetting industry. Lorem Ipsum has been
                  the industry's standard dummy text ever since the 1500s, when
                  an unknown printer took a galley of type and scrambled it to
                  make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting,
                  remaining essentially unchanged. It was popularised in the
                  1960s with the release of Letraset sheets containing Lorem
                  Ipsum passages, and more recently with desktop publishing
                  software like Aldus PageMaker including versions of Lorem
                  Ipsum.
                </p>
                <p>
                  <b>Deliverables: </b>Deliverables
                </p>
                <p>
                  <b>Total Amount: ₹</b>Amount
                </p>
              </div>
            )}
            <Button type="submit">Continue to payment</Button>
          </div>
        </div>
      </div>
    </>
  );
}
