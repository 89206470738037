import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Changepass() {
  return (
    <>
      <div class="text-center">
        <div class="col-md-4 offset-4">
          <Form>
            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Label>New Password</Form.Label>
              <Form.Control type="password" placeholder="Enter New Password" />
            </Form.Group>
          </Form>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </div>
    </>
  );
}
