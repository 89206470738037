import React from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router';
import '../../css/master.css';

export default function ViewTickets() {

	const navigate = useNavigate();

	return(
		<>
			<ListGroup as="ul">
                <ListGroup.Item as="li" active>TID1</ListGroup.Item>
                <ListGroup.Item as="li">TID2</ListGroup.Item>
                <ListGroup.Item as="li">TIDY</ListGroup.Item>
            </ListGroup>
		</>
	)
}