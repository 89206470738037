import React, { useState, useEffect } from "react";
import { Button, Card, ListGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router";
import "../../css/master.css";

export default function ViewBillDetails() {
  const [isUnpaid, setvar] = useState(true);

  const navigate = useNavigate();

  return (
    <>
      {/* {setvar(parentToChild)} */}
      <div className="left">
        {isUnpaid ? (
          <p className="isUnpaid">THIS BILL IS NOT YET PAID</p>
        ) : (
          <p className="isPaid">THIS BILL IS PAID</p>
        )}
        {isUnpaid ? (
          <></>
        ) : (
          <p>
            <b>Transaction ID: </b>TxnId
          </p>
        )}
        <p>
          <b>Invoice Number: </b>InvNum
        </p>
        <p>
          <b>Invoice Date: </b>Date
        </p>
        <p>
          <b>Billed To: </b>Org name
        </p>
        <p>
          <b>Project: </b>Project Name
        </p>
        <p>
          <b>Invoice Details: </b>Lorem Ipsum is simply dummy text of the
          printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took
          a galley of type and scrambled it to make a type specimen book. It has
          survived not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in
          the 1960s with the release of Letraset sheets containing Lorem Ipsum
          passages, and more recently with desktop publishing software like
          Aldus PageMaker including versions of Lorem Ipsum.
        </p>
        <p>
          <b>Deliverables: </b>Deliverables
        </p>
        <p>
          <b>Total Amount: ₹</b>Amount
        </p>
      </div>
      <div className="text-center">
        {isUnpaid ? (
          <Button
            variant="primary"
            type="submit"
            onClick={() => navigate("/payment")}
          >
            Pay Bill
          </Button>
        ) : (
          <></>
        )}

        <Button variant="primary" type="submit" className="downloadBtn">
          Download
        </Button>

        <Button
          variant="primary"
          type="submit"
          className="raiseDisputeBtn"
          onClick={() => navigate("/support")}
        >
          Raise Dispute
        </Button>
      </div>
    </>
  );
}
