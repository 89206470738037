import React from 'react';
import { Button, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router';

export default function CardView() {

	const navigate = useNavigate();

	return(
		<>
			<div class="container text-center">
				<div class="row">
					<div class="col-md-3">
						<Card style={{ width: '15rem', height: '12rem'  }}>
							<Card.Body>
								<Card.Title>Projects</Card.Title>
								<Card.Text>
									View and manage your projects.
								</Card.Text>
								<Button variant="primary" onClick={() => navigate('/projects')}>View</Button>
							</Card.Body>
						</Card>
					</div>
					<div class="col-md-3">
						<Card style={{ width: '15rem', height: '12rem'  }}>
							<Card.Body>
								<Card.Title>Support</Card.Title>
								<Card.Text>
								View/Raise support/feedback tickets
								</Card.Text>
								<Button variant="primary" onClick={() => navigate('/support')}>Open</Button>
							</Card.Body>
						</Card>
					</div>
					<div class="col-md-3">
						<Card style={{ width: '15rem', height: '12rem'  }}>
							<Card.Body>
								<Card.Title>Bills &amp; Invoice</Card.Title>
								<Card.Text>
									View bills and invoices.
								</Card.Text>
								<Button variant="primary" onClick={() => navigate('/bills')}>View</Button>
							</Card.Body>
						</Card>
					</div>
					<div class="col-md-3">
						<Card style={{ width: '15rem', height: '12rem'  }}>
							<Card.Body>
								<Card.Title>Payment</Card.Title>
								<Card.Text>
									Make payments.
								</Card.Text>
								<Button variant="primary" onClick={() => navigate('/payment')}>Open</Button>
							</Card.Body>
						</Card>
					</div>
				</div>
			</div>
		</>
	)
}