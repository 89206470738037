import React, { useState, useEffect } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router';
import '../../css/master.css';

import ViewBillDetails from './ViewBillDetails';

export default function Bills() {

    const navigate = useNavigate();

    const [variable, setvar] = useState(true)

    return (
        <>
            <div class="container text-center">
                <div className='row'>
                    <div className='col-md-3'>
                        <h6 class="centerHead"><u>Bills &amp; Invoices</u></h6>
                        <Button onClick={() => setvar(true)}>
                            Pending Bills
                        </Button>
                        &nbsp;
                        <Button onClick={() => setvar(false)}>
                            Paid Bills
                        </Button>
                        <hr></hr>
                        <ListGroup as="ul">
                            <ListGroup.Item as="li" active>Bill Date - Bill Number</ListGroup.Item>
                            <ListGroup.Item as="li">12.05.2022 - #23456</ListGroup.Item>
                            <ListGroup.Item as="li">Bill Date - Bill Number</ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div class="col-md-1">
                        <div class="vll"></div>
                    </div>
                    <div className='col-md-8'>
                        <ViewBillDetails/>
                    </div>
                </div>
            </div>
        </>
    )
}