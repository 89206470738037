import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router';
import '../../css/master.css';
import ViewTickets from '../components/ViewTickets';
import SelectProjectTickets from '../components/SelectProjectTicket';
import RaiseTicketForm from './RaiseTicketForm';
import ViewTicketInfo from './ViewTicketInfo';

export default function Tickets() {

	const navigate = useNavigate();

    const [variable,setvar] = useState(false)

	return(
		<>
			<div class="container text-center">
				<div className='row'>
                    <div className='col-md-3'>
                        <h6 class="centerHead"><u>View Tickets</u></h6>
                        <Button onClick={() => setvar(true)}>
                            View Tickets
                        </Button>
                        &nbsp;
                        <Button onClick={() => setvar(false)}>
                            Raise a Ticket
                        </Button>
                        <hr></hr>
                        { variable ? <ViewTickets/> : <SelectProjectTickets/> }
                    </div>
                    <div class="col-md-1">
                        <div class="vll"></div>
                    </div>
                    <div className='col-md-8'>
                        { variable ? <ViewTicketInfo/> : <RaiseTicketForm/> }
                    </div>
                </div>
			</div>
		</>
	)
}