import React from "react";
import "./Navbar.css";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router";

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <nav>
      <div className="logo">
        <img src="./bklogo.png" alt="logo" />
      </div>
      <div style={{ alignSelf: "center" }}>
        <Button variant="primary" onClick={() => navigate("/changepass")}>
          Change Password
        </Button>
      </div>
      <div style={{ alignSelf: "center" }}>
        <Button variant="danger">Log Out</Button>
      </div>
    </nav>
  );
};

export default Navbar;
