import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import CardView from "./Components/Pages/CardView";
import Tickets from "./Components/Pages/Support/Tickets";
import Bills from "./Components/Pages/Bills/Bills";
import PayBill from "./Components/Pages/Bills/PayBill";
import Projects from "./Components/Pages/Projects/Projects";
import Login from "./Components/Login/login";
import Changepass from "./Components/Pages/Changepass";
import Navbar from "./Components/Navbar/Navbar";

function App() {
  return (
    <div className="AppPage">
      <Router>
        <Navbar />
        <div class="container">&nbsp;</div>
        <CardView />
        <div class="container">&nbsp;</div>
        <hr></hr>
        <Routes>
          <Route path="/support" element={<Tickets />} />
          <Route path="/bills" element={<Bills />} />
          <Route path="/payment" element={<PayBill />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/login" element={<Login />} />
          <Route path="/changepass" element={<Changepass />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
