import React, { useState, useEffect } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router';
import '../../css/master.css';

export default function Projects() {

    const navigate = useNavigate();

    const [variable, setvar] = useState(true)

    return (
        <>
            <div class="container text-center">
                <div className='row'>
                    <div className='col-md-3'>
                        <p><b>ORGANISATION NAME</b></p>
                        <hr></hr>
                        <h6 class="centerHead"><u>Projects</u></h6>
                        <hr></hr>
                        <ListGroup as="ul">
                            <ListGroup.Item as="li" active>PROJECT 1</ListGroup.Item>
                            <ListGroup.Item as="li">Project 2</ListGroup.Item>
                            <ListGroup.Item as="li">PrOjeKt 3</ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div class="col-md-1">
                        <div class="vll"></div>
                    </div>
                    <div className='col-md-8'>
                        <div className='left'>
                            <p><b>Project Name: </b>Inventory Management System</p>
                            <p><b>Project Details: </b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            <p><b>Project Status: </b>Prod Deployment Done. Updates Ongoing.</p>
                            <p><b>Billing Preference*: </b>Quarterly (3 Months)</p>
                            <p><b>Active Subscriptions: </b>Web Hosting, Database Hosting, Annual Maintainance</p>
                        </div>
                        <div className='left legend'>
                            *To change your billing preference, please <a onClick={navigate('/support')}>raise a support ticket</a> or contact your support agent.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}