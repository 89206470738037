import React from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router';
import '../../css/master.css';

export default function RaiseTicketForm() {

	const navigate = useNavigate();

	return(
		<>
			<Form>
                <Form.Group className="mb-3 text-start" controlId="formBasicText">
                    <Form.Label>Please Describe The Issue</Form.Label>
                    <Form.Control as="textarea" rows={6} placeholder="Description" />
                </Form.Group>
                <div className='text-center'>
                    <Button variant="primary" type="submit">
                        Raise Ticket
                    </Button>
                </div>
            </Form>
		</>
	)
}