import React from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router';
import '../../css/master.css';

export default function SelectProjectTicket() {

	const navigate = useNavigate();

	return(
		<>
			<Form>
                <Form.Group className="mb-3" controlId="formBasicText">
                    <Form.Label>Select Project</Form.Label>
                    <Form.Select aria-label="Default select example">
                        <option>Select Project</option>
                        <option value="1">Project 1</option>
                        <option value="2">Project 2</option>
                        <option value="3">Project 3</option>
                        <option value="3">Project 4</option>
                        <option value="3">Project 5</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicText">
                    <Form.Label>Select Ticket Type</Form.Label>
                    <Form.Select aria-label="Default select example">
                        <option>Select Ticket Type</option>
                        <option value="1">Support</option>
                        <option value="2">Feedback</option>
                        <option value="3">Complaint</option>
                        <option value="3">Dispute</option>
                        <option value="3">Other</option>
                    </Form.Select>
                </Form.Group>
            </Form>
		</>
	)
}